<template>
    <el-dialog
            title="在线聊天"
            v-model="dialogVisible"
            :width="dialogWidth"
            append-to-body
            custom-class="imModal"
            :close-on-press-escape="false"
    >
        <div class="imWrap">
            <div class="userList">
                <div
                        class="item"
                        v-for="(item,index) in userList"
                        :key="index"
                        @click="handleSelectUser(item.userId)"
                        :class="[selectUserId === item.userId ?'active':'']"
                >
                    <img :src="item.avatar"/>
                    <div class="info">
                        <p>{{item.username}}</p>
                        <p>{{item.content}}</p>
                    </div>
                    <div class="date">{{dayjs(item.sendTime).format('HH:mm')}}</div>
                </div>
            </div>
            <div class="imContent">
                <div class="msgContent" ref="msgContent">
                    <div
                            v-for="(item,index) in msgList"
                            :key="index"
                            class="item"
                            :class="[item.source === 1 ? 'right' : 'left']"
                            :id="item.id"
                    >
                        <img v-if="item.source === 1" :src="avatar" mode class="avatar"/>
                        <img v-else :src="userAvatar" mode class="avatar"/>

                        <div class="content">
                            <div v-if="item.type === 0">{{ item.content }}</div>
                            <div v-else>
                                <el-image
                                        style="width: 100px; height: 100px"
                                        :src="item.content"
                                        :preview-src-list="[item.content]"
                                ></el-image>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form">
                    <el-upload
                            :headers="headers"
                            multiple
                            :show-file-list="false"
                            :action="uploadImgUrl"
                            :on-success="handleUploadSuccess"
                            :before-upload="handleBeforeUpload"
                            ref="imageUpload"
                            :limit="10"
                            accept="image/*"
                    >
                        <el-button size="medium" type="primary">图片</el-button>
                    </el-upload>
                    <el-input placeholder="请输入内容" v-model="content" @change="(v)=>onEnter(v)"></el-input>
                    <el-button size="medium" type="primary" @click="contentChange">发送</el-button>
                </div>
            </div>
        </div>
    </el-dialog>
</template>
<script setup>
    const {proxy} = getCurrentInstance();
    import {getToken} from "@/utils/auth";
    import {getWebSocket} from "@/utils/websocket-client";
    import useSocketStore from "@/store/modules/socket";
    import ImageUpload from "@/components/ImageUpload";
    import {messageList, messageInfo} from "@/api/system/scoket";
    import dayjs from "dayjs";


    const baseUrl = import.meta.env.VITE_APP_BASE_API;
    const uploadImgUrl = ref(
        import.meta.env.VITE_APP_BASE_API + "/common/uploadImg"
    ); // 上传的图片服务器地址
    const headers = ref({Authorization: "Bearer " + getToken()});
    const noticeMsg = computed(() => useSocketStore().noticeMsg);


    const ws = getWebSocket();
    // 是否显示弹出层
    const dialogVisible = ref(false);
    const dialogWidth = ref("0")
    const content = ref("");
    const msgContent = ref("");

    const state = reactive({
        msgList: [],
        userList: [],
        selectUserId: null,
        page: 1,
        lastId: 0,
        userAvatar: null,
        avatar: null,
        isLoading: false,
        isMore: true,
        isFirst: true
    });

    const {
        msgList,
        userList,
        selectUserId,
        page,
        lastId,
        userAvatar,
        avatar,
        isLoading,
        isMore,
        isFirst
    } = toRefs(state);

    // 监听接收到信息
    watch(noticeMsg, (newVal, oldVal) => {
        msgList.value.push({
            content: newVal.msg,
            type: newVal.type,
            source: 0
        });
        changeScroll();
    });

    // 控制滚动条
    function changeScroll() {
        let scroll = msgContent.value;
        setTimeout(function () {
            const height = scroll.scrollHeight;
            scroll.scrollTo(0, height);
        }, 100);
    }

    // onBeforeUnmount(() => {
    //   console.log('xxx')
    // }),

    // 弹出聊天框
    function show() {
        dialogVisible.value = true;
        useSocketStore().updateNotice();
        // useSocketStore().isNotice.value = false
        setTimeout(() => {
            messageList().then(res => {
                userList.value = res.data;
                if (res.data && res.data.length > 0) {
                    selectUserId.value = res.data[0].userId;
                    imRecord(res.data[0].userId);
                    let scroll = msgContent.value;
                    scroll.addEventListener("scroll", onScroll, true);
                }
            });
        }, 100);
    }

    // onMounted(() => {
    //   let target = msgContent.value;
    //   console.log(target.scrollTop);
    // });

    // 滚动条监听
    function onScroll() {
        let target = msgContent.value;
        if (target.scrollTop < 30 && isMore.value && !isLoading.value) {
            isLoading.value = true;
            let lastTarget = null;
            if (msgList.value.length) {
                lastTarget = msgList.value[0].id;
            }
            console.log("请求下一页数据");
            page.value += 1;
            console.log(msgList.value);
            imRecord(selectUserId.value, lastTarget);
        }
    }

    // 切换用户
    function handleSelectUser(userId) {
        selectUserId.value = userId;
        // 初始化数据
        msgList.value = [];
        userAvatar.value = null;
        userAvatar.avatar = null;
        isLoading.value = false;
        page.value = 1;
        isMore.value = true;
        isFirst.value = true;
        lastId.value = 0;
        imRecord(userId);
    }

    // 获取聊天记录
    function imRecord(userId, lastTarget) {
        messageInfo({userId, page: page.value, lastId: lastId.value}).then(res => {
            // console.log(isLoading.value);
            userAvatar.value = res.data.userAvatar;
            avatar.value = res.data.avatar;
            console.log(res.data.avatar);
            console.log(res.data.userAvatar);
            lastId.value = res.data.lastId;
            // 头部添加记录
            
            msgList.value = [...res.data.contentList, ...msgList.value];

            console.log(msgList.value);
            // 头部添加记录后 滚动到之前的位置
            if (lastTarget) {
                console.log(lastTarget);
                setTimeout(() => {
                    const lastOffsetTop = document.getElementById(lastTarget).offsetTop;
                    msgContent.value.scrollTo(0, lastOffsetTop);
                }, 1);
            }
            setTimeout(() => {
                isLoading.value = false;
            }, 10);

            if (res.data.contentList.length <= 0) {
                isMore.value = false;
            }
            if (isFirst.value) {
                changeScroll();
                isFirst.value = false;
            }
        });
    }

    // 发送信息
    function contentChange() {
        if (content.value) {
            sendMsg(content.value, 0);
            content.value = "";
        }
    }

    // 监听发送消息
    function onEnter(v) {
        if (content.value) {
            sendMsg(content.value, 0);
            content.value = "";
        }
    }

    // 上传成功回调
    function handleUploadSuccess(res, file) {
        if (res.code === 200) {
            const image = res.data.url;
            sendMsg(image, 1);
        } else {
            proxy.$modal.msgError(`上传失败`);
        }
    }

    // 发送消息
    function sendMsg(msg, type) {
        console.log(selectUserId.value)
        ws.send({command: "message", toUserId: selectUserId.value, msg, type});
        // 发送回调
        const id = `id_${new Date().getTime()}`;
        msgList.value.push(
            JSON.parse(
                JSON.stringify({
                    content: msg,
                    source: 1,
                    type,
                    id
                })
            )
        );
        changeScroll();
    }

    //调整弹出框大小
    function setDialogWidth() {
        var val = document.body.clientWidth
        console.log(val);
        const def = 700 // 默认宽度
        if (val < def) {
            dialogWidth.value = '100%'
        } else {
            dialogWidth.value = def + 'px'
        }
    }

    onMounted(() => {
        window.onresize = () => {
            return (() => {
                setDialogWidth()
            })()
        }
    })

    // 暴露方法出去
    defineExpose({show});

    setDialogWidth();
</script>
<style lang="scss">
    $borderColor: #999;
    .imModal {
        .userList {
            border-right: 1px solid $borderColor;
            overflow-y: auto;
            max-width: 30%;
            min-width: 20%;
            .item {
                padding: 8px 10px;
                display: flex;
                align-items: center;
                cursor: pointer;
                border-bottom: 1px solid $borderColor;
                min-height: 68px;

                &.active {
                    background: #efefef;
                }

                img {
                    width: 35px;
                    height: 35px;
                    border-radius: 5px;
                    margin-right: 8px;
                }

                .info {
                    flex: 1;

                    p {
                        margin: 5px 0;
                        width: 90px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }
                }

                .date {
                    font-size: 14px;
                }
            }
        }

        .el-dialog__body {
            padding: 0;
        }
    }

    .imWrap {
        height: 500px;
        display: flex;
        border: 1px solid $borderColor;

        .list {
            border-right: 1px solid $borderColor;
        }

        .imContent {
            flex: 1;
            display: flex;
            flex-direction: column;

            .msgContent {
                border-bottom: 1px solid $borderColor;
                padding: 8px;
                flex: 1;
                overflow-y: auto;

                .item {
                    max-width: 100%;
                    margin-bottom: 32px;
                    display: flex;
                    padding-left: 0;
                    // padding-right: 120px;

                    &:last-child {
                        margin-bottom: 0;
                    }

                    &.right {
                        flex-direction: row-reverse;
                        // padding-left: 120px;
                        padding-right: 0;

                        .avatar {
                            margin-left: 40px;
                            margin-right: 0;
                        }
                    }

                    .avatar {
                        width: 40px;
                        height: 40px;
                        border-radius: 5px;
                        margin-right: 20px;
                    }

                    &.left {
                        .content {
                            &::after {
                                content: "";
                                position: absolute;
                                width: 0;
                                height: 0;
                                border: 10px solid transparent;
                                border-right-color: #f8f8f8;
                                left: -20px;
                                top: 10px;
                            }
                        }
                    }

                    &.right {
                        .content {
                            text-align: right;

                            &::after {
                                content: "";
                                position: absolute;
                                width: 0;
                                height: 0;
                                border: 10px solid transparent;
                                border-left-color: #f8f8f8;
                                right: -20px;
                                top: 10px;
                            }
                        }
                    }

                    .content {
                        padding: 10px;
                        background: #f8f8f8;
                        border-radius: 10px;
                        font-size: 15px;
                        position: relative;

                        image {
                            width: 120px;
                            height: 120px;
                        }
                    }
                }
            }

            .form {
                height: 40px;
                display: flex;
                align-items: center;

                .el-input__inner {
                    flex: 1;
                    margin: 0px 5px;
                }
            }
        }
    }
</style>