<template>
  <section class="app-main">
    <router-view v-slot="{ Component, route }">
      <transition name="fade-transform" mode="out-in">
        <keep-alive :include="cachedViews">
          <component :is="Component" :key="route.path" />
        </keep-alive>
      </transition>
    </router-view>
    <div class="fixed-notic" @click="showIMModal">客<span v-if="showTips" class="tips"></span></div>
    <IMModal ref="IMModalRef"></IMModal>
  </section>
</template>

<script setup>
import IMModal from "@/components/IMModal";
import useTagsViewStore from "@/store/modules/tagsView";
import useSocketStore from "@/store/modules/socket";

const tagsViewStore = useTagsViewStore();
const route = useRoute();
tagsViewStore.addCachedView(route);
const cachedViews = computed(() => {
  return tagsViewStore.cachedViews;
});

const IMModalRef = ref(null);
const isNotice = computed(() => useSocketStore().isNotice);

const state = reactive({
  showTips: isNotice,
});

const {
  showTips,
} = toRefs(state);

// 显示聊天窗口
function showIMModal() {
  IMModalRef.value.show();
}
</script>

<style lang="scss" scoped>
.app-main {
  /* 50= navbar  50  */
  min-height: calc(100vh - 50px);
  width: 100%;
  position: relative;
  overflow: hidden;
}

.fixed-header + .app-main {
  padding-top: 50px;
}

.hasTagsView {
  .app-main {
    /* 84 = navbar + tags-view = 50 + 34 */
    min-height: calc(100vh - 84px);
  }

  .fixed-header + .app-main {
    padding-top: 84px;
  }
}
.fixed-notic {
  position: fixed;
  width: 40px;
  height: 40px;
  bottom: 5vh;
  right: 50px;
  z-index : 9999;
  display: flex;
  padding: 10px;
  align-items: center;
  justify-content: center;
  background: #304156;
  border-radius: 50%;
  color: #fff;
  cursor: pointer;
  .tips{
    position: absolute;
    width: 10px;
    height:10px;
    border-radius: 50%;
    right:0;
    top:0;
    background: red;
  }
}
</style>

<style lang="scss">
// fix css style bug in open el-dialog
.el-popup-parent--hidden {
  .fixed-header {
    padding-right: 17px;
  }
}
</style>