import request from '@/utils/request'

// 咨询历史列表
export function messageList(query) {
  return request({
    url: '/message/list',
    method: 'get',
    params: query
  })
}


// 聊天内容
export function messageInfo(query) {
  return request({
    url: '/message/info',
    method: 'get',
    params: query
  })
}
