import { getWBToken } from '@/api/login'
import defAva from '@/assets/images/profile.jpg'
import { getWebSocket } from '@/utils/websocket-client';



const url = 'wss://api.bravo.love/spring-ws';

const useSocketStore = defineStore(
  'socket',
  {
    state: () => ({
      noticeMsg: '',
      isNotice:true
    }),
    actions: {
      updateNotice(){
        this.isNotice = false;
      },
      initWebsocket() {
        getWBToken().then(res => {
          const { data: { token } } = res;
          const ws = getWebSocket();
          ws.open(`${url}?token=${token}`);
          ws.onmessage = ({ data }) => {
            if (data) {
              const resData = JSON.parse(data);
              if (resData.msg != 'pong') {
                console.log('收到推送消息')
                this.noticeMsg = resData;
                this.isNotice = true;
              }
            }
          };
        })
      },
    }
  })

export default useSocketStore
